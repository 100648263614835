export default {
  s3: {
    REGION: "ap-southeast-1",
    BUCKET: "house99-app-uploads"
  },
  apiGateway: {
    REGION: "ap-southeast-1",
    URL: "https://k3yz87qbi0.execute-api.ap-southeast-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "ap-southeast-1",
    USER_POOL_ID: "ap-southeast-1_vPS4GkR4u",
    APP_CLIENT_ID: "6619ij9051db2urefn0v8uepno",
    IDENTITY_POOL_ID: "ap-southeast-1:98b866b6-bdab-4827-9740-faf862c895ff"
  }
};
