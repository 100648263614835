import React, {
  useState,
  useEffect
} from 'react';

import { onError } from "../libs/errorLib";
import { catByName } from "./ProdMeta"
// import { useAppContext } from "../libs/contextLib";

// import ExploreContainer from '../components/ExploreContainer';
import './Tab1.css';
import '../theme/variables.css';

import { API } from "aws-amplify";
import {
  IonContent,
  IonRow,
  IonCol,
  IonImg,
  IonList,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonPopover,
  IonButton,
  IonGrid,
  IonInput,
  IonCard,
  IonCardTitle,
  IonCardHeader,
  IonCardSubtitle,
  IonCardContent,
  IonLabel,
  IonIcon,
  IonItemDivider,
} from '@ionic/react';

import {
  Pagination,
  Container,
  Row,
  Col,
} from 'react-bootstrap';
// import  from 'react-bootstrap/Container'

import {
  bugOutline
} from 'ionicons/icons';

import { useHistory } from "react-router-dom";
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;

const Products: React.FC = () => {

  const [cat, setCat] = useState<string>('Refrigerators');
  const [orderby, setOrderby] = useState<string>('');
  const [isAsc, setIsAsc] = useState<boolean>(true);

  const [widthMin, setWidthMin] = useState<number>(NaN);
  const [widthMax, setWidthMax] = useState<number>(850);

  const [heightMin, setHeightMin] = useState<number>(NaN);
  const [heightMax, setHeightMax] = useState<number>(2100);

  const [depthMin, setDepthMin] = useState<number>(NaN);
  const [depthMax, setDepthMax] = useState<number>(NaN);

  const [showPopover, setShowPopover] = useState<string>("");
  // const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated] = useState(false);
  // const { isAuthenticated } = useAppContext();

  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [activePage, setActivePage] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(0);

  var url = new URL(window.location.href);
  const pathSeg = url.pathname.split('/');
  const itemPerPage = 10
  const history = useHistory();


  async function saveUserFilters() {
    await Storage.set({
      key: 'user-filters',
      value: JSON.stringify({
        catName: cat,
        widthMin: widthMin,
        widthMax: widthMax,
        heightMin: heightMin,
        heightMax: heightMax,
        depthMin:  depthMin,
        depthMax:  depthMax,
      })
    });
  }

  // JSON "get" example
  async function getUserFilters() {
    const ret: any = await Storage.get({ key: 'user-filters' });
    if( ret.value ){
      const userFilter = JSON.parse(ret.value);
      setWidthMin(userFilter.widthMin);
      setWidthMax(userFilter.widthMax);
      setHeightMin(userFilter.heightMin);
      setHeightMax(userFilter.heightMax);
      setDepthMin(userFilter.depthMin);
      setDepthMax(userFilter.depthMax);
    }
    // return userFilter
  }

  const paginationBasic = (total:number) => {

    function updateToPage(toPage: number){
      setActivePage(toPage);
      history.push('/home-appliances/'+toPage);
      updateProdList(toPage);
    }
    const prodItems = [];
    const maxButton = 5

    const pageFromUrl = (pathSeg.length > 2 && /^\d+$/.test(pathSeg[2]))? parseInt(pathSeg[2]): 1
    const firstPage = pageFromUrl > maxButton / 2 ? Math.ceil(pageFromUrl - (maxButton/2)) : 1
    const lastPage  = Math.ceil(total/itemPerPage)
    for (let number = firstPage; number <= lastPage && number - firstPage < maxButton; number++) {
      prodItems.push(
        <Pagination.Item key={number} active={number === activePage}
          onClick={(event)=>{updateToPage(number);}}>
          {number}
        </Pagination.Item>,
      );
    }
    return <Pagination className="ion-align-right">
      {//<Pagination.First key='1001'  onClick={(event)=>{updateToPage(1)}}/>
      }
      <Pagination.Prev key='1002'   onClick={(event)=>{updateToPage(Math.max(activePage-1,1))}}/>
      {prodItems}
      <Pagination.Next key='999'    onClick={(event)=>{updateToPage(Math.min(activePage+1,lastPage))}}/>
      {//<Pagination.Last key='9999'   onClick={(event)=>{updateToPage(lastPage)}}/>
      }
    </Pagination>
  }

  async function fetchProds(currentPage: number) {

    let dimFilters : any = {}

    if( widthMin ){
      dimFilters.widthMin = widthMin;
    }
    if( widthMax ){
      dimFilters.widthMax = widthMax;
    }
    if( heightMin ){
      dimFilters.heightMin = heightMin;
    }
    if( heightMax ){
      dimFilters.heightMax = heightMax;
    }
    if( depthMin ){
      dimFilters.depthMin = depthMin;
    }
    if( depthMax ){
      dimFilters.depthMax = depthMax;
    }

    if(orderby.length> 0){
      dimFilters.orderby = orderby;
      dimFilters.asc = isAsc ? "true":"false";
    }

    const productType= Object.keys(catByName[cat]).join(',')
    //
    // await saveUserFilters();

    return API.get("notes", "/list-products"  , {
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {
        ...dimFilters,
        "productType": productType,
        "take": itemPerPage,
        skip: (currentPage - 1)* itemPerPage
      },
    });
  }

  const updateProdList= async(toPage: number)=>{

    if( toPage === -1 ){
      toPage = (pathSeg.length > 2 && /^\d+$/.test(pathSeg[2]))? parseInt(pathSeg[2]): 1
    }
    toPage = Math.max(toPage,1)
    setIsLoading(true);
    try {
      setProducts([]);
      await saveUserFilters();
      const prods = await fetchProds(toPage);
      setProducts(prods.data.items);
      // console.log(prods.data)
      setTotalItems(prods.data.total);
    } catch (e) {
      onError(e);
    }

    // setActivePage(currentPage) ;
    setIsLoading(false);
  }

  useEffect(() => {
    if( cat=='Refrigerators'){
      // ONlY run runce with
      setWidthMax(800);
      // setDepthMax(700);
      // setHeightMin(500);

      getUserFilters();
    }
  }, []);

  useEffect(() => {

    let isMounted = true;
    async function onLoad() {
      // if (!isAuthenticated) {
      //   console.log("No isAuthenticated")
      //   return;
      // }
      // await getUserFilters()
      // setCat('fridge');
      updateProdList(-1);
    }
    if(isMounted){
      onLoad();
    }
    console.log("UE....")
    return function cleanup() {
      isMounted = false
    };
  }, [isAuthenticated, cat, isAsc, orderby ]);

  async function  doSearch() {
    // return API.get("notes", "/list-products", myInit);
    history.push('/home-appliances/')
    console.log("search ..");
    updateProdList(-1);
  }
  const getDim: {[index: string]:{[index: string]:any}} = {
    "min":{
      "Width":  widthMin,
      "Height": heightMin,
      "Depth":  depthMin
    },
    "max":{
      "Width":  widthMax,
      "Height": heightMax,
      "Depth":  depthMax,
    },
  }

  const setDim: {[index: string]:{[index: string]:any}}= {
    "min":{
      "Width":  setWidthMin,
      "Height": setHeightMin,
      "Depth":  setDepthMin
    },
    "max":{
      "Width":  setWidthMax,
      "Height": setHeightMax,
      "Depth":  setDepthMax,
    },
  }

  const MyPopover = (dimName: string) => {

    return <IonPopover
      cssClass="MyPopoverClass"
      isOpen={showPopover === dimName }
      onDidDismiss={e => setShowPopover("")}
    >
      <IonCardContent>
        <IonList>
          <IonItemDivider><b>{dimName}</b>&nbsp;filter</IonItemDivider>
          <IonItem>
            {
            <IonGrid>
              <IonRow className="ion-align-items-start">
                <IonCol size='1'>
                </IonCol>
                <IonCol size='4'>
                  <IonLabel position="stacked" >Min</IonLabel>
                  <IonInput placeholder="No Min" type="number" step="10" min='0'
                    value={getDim['min'][dimName]}
                    onIonChange={e => {
                      setDim['min'][dimName](parseFloat(e.detail.value!))}
                    }> </IonInput>
                </IonCol>
                <IonCol size='1'>
                  <IonLabel position="stacked">&nbsp;</IonLabel>
                  <IonLabel position="stacked">-</IonLabel>
                </IonCol>
                <IonCol size='4'>
                  <IonLabel position="stacked" >Max</IonLabel>
                  <IonInput placeholder="No Max" type="number" step="10" min='0'
                    value={getDim['max'][dimName]}
                    onIonChange={e => {
                      setDim['max'][dimName]( parseFloat(e.detail.value!))
                    }}></IonInput>
                </IonCol>
                <IonCol size='2'>
                  <IonLabel position="stacked" className="ion-text-center">&nbsp;</IonLabel>
                  <IonInput disabled placeholder="mm" ></IonInput>
                </IonCol>
              </IonRow>
              <IonRow className="ion-align-items-start">
                <IonCol size='4'>
                  <IonButton color="medium" size='small' onClick={()=>{setDim['min'][dimName](NaN)}}>No Min</IonButton>
                </IonCol>
                  <IonCol size='1'/>
                    <IonCol size='4'>
                  <IonButton color="medium"  size="small" onClick={()=>{setDim['max'][dimName](NaN)}}>No Max</IonButton>
                    </IonCol>
                  </IonRow>
            </IonGrid>
          }
          </IonItem>
        </IonList>
        <IonButton size="default" onClick={() => {
          setShowPopover("");
          doSearch()
        }}>Apply</IonButton>
      </IonCardContent>
    </IonPopover>
  }

  document.title = "Home Appliances :  " + cat + " - HouseInnov"
  return (
    <IonContent>
      <IonGrid>
        <IonRow>
          <IonCol size="12" sizeSm="12" sizeMd="4" sizeLg="3">
          <div>
            <IonList >
              <IonItem color="light" >
                <IonSelect className='applianceSelect' mode='ios' interface="popover"
                  value={cat} placeholder="Select"
                  onIonChange={e => setCat(e.detail.value)}>
                  <IonSelectOption value="Refrigerators">Search <h3>Refrigerator &nbsp;</h3></IonSelectOption>
                  <IonSelectOption value="Ovens">Search Oven </IonSelectOption>
                  <IonSelectOption value="Hobs">Search Hob </IonSelectOption>
                  {
                  // <IonSelectOption value="others">Others</IonSelectOption>
                  }
                </IonSelect>
                  {
                    // <IonLabel>AA</IonLabel>
                  }
              </IonItem>

              <IonItem>
                <IonLabel>Width</IonLabel>
                {MyPopover('Width')}
                <IonButton color="medium" className="filterButton" onClick={() => setShowPopover("Width")}>{
                  (!widthMin && !widthMax) ? "Any" :
                  (widthMin ? widthMin + " mm" : "No Min") + " - " + (widthMax? widthMax + " mm": "No Max")
                } </IonButton>
              </IonItem>
              <IonItem>
                <IonLabel>Height</IonLabel>
                {MyPopover('Height')}
                <IonButton color="medium" className="filterButton" onClick={() => setShowPopover("Height")}>{
                  (!heightMin && !heightMax) ? "Any" :
                  (heightMin ? heightMin + " mm"  : "No Min") + " - " + (heightMax? heightMax + " mm" : "No Max")
                }</IonButton>
              </IonItem>
              <IonItem>
                <IonLabel>Depth</IonLabel>
                {MyPopover('Depth')}
                <IonButton color="medium" className="filterButton" onClick={() => setShowPopover("Depth")}>{
                  (!depthMin && !depthMax) ? "Any" :
                  (depthMin ? depthMin + " mm" : "No Min") + " - " + (depthMax? depthMax + " mm" : "No Max")
                }</IonButton>
              </IonItem>

              <IonItem>
                <IonLabel>Sort by</IonLabel>
                <IonSelect value={orderby} mode='ios' placeholder="Select" interface='popover' onIonChange={e => {
                  setOrderby(e.detail.value)
                }}>
                  <IonSelectOption value="width">Width</IonSelectOption>
                  <IonSelectOption value="height">Height</IonSelectOption>
                  <IonSelectOption value="depth">Depth</IonSelectOption>
                  {  cat == 'Refrigerators' || cat == 'Ovens' ?
                  <IonSelectOption value="capacity">Capacity</IonSelectOption>:
                  <div></div>
                  }
                </IonSelect>
                <IonSelect value={isAsc} mode='ios' interface='popover' onIonChange={e => {
                  setIsAsc(e.detail.value)
                }}>
                  <IonSelectOption value={true}>ASC</IonSelectOption>
                  <IonSelectOption value={false}>DESC</IonSelectOption>
                </IonSelect>
              </IonItem>

              <br/>
              <IonLabel></IonLabel>
              <IonButton color="primary" size="default" onClick={() => doSearch()}>Search</IonButton>
              <IonLabel></IonLabel>
            </IonList>
          </div>
          </IonCol>
          <IonCol>
              <IonRow>&nbsp;
                <IonCol size="7" sizeSm="7" sizeMd='7'>
                    <h6>{
                    isLoading? "Loading .." :  "Found " + totalItems + " " +
                    (cat ? cat.slice(0,-1).toLowerCase() + (totalItems>1?"s":""): "products" ) + " : "
                  }</h6>
                </IonCol>
                <IonCol>
                  <IonItem  lines='none'>
                    <IonItem slot='end' lines='none'>
                      {paginationBasic(totalItems)}
                    </IonItem>
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                    {
                      products.map(({ id, brand, title, imagesUri, widthCm, heightCm, depthCm, extInfo }) => (

                        <IonCard className="applianceCard" key={id}
                          onClick={()=>{history.push("/home-appliance/" +id)}}
                        >
                        <IonGrid>
                          <IonRow className="ion-align-items-start">
                            <IonCol size='3'>
                              <div>&nbsp;</div>
                              {/*<IonImg src="./assets/fridge2.png" />*/}
                              {imagesUri?
                              <IonImg src={"https://house99-res.s3-ap-southeast-1.amazonaws.com/product_imgs/" + JSON.parse(imagesUri)[0]} />:
                              <IonIcon icon={bugOutline} size='large'/>

                              }
                            </IonCol>
                            <IonCol>
                              <IonCardHeader>
                                <IonCardSubtitle>Fridge - {brand}</IonCardSubtitle>
                                {

                                  <IonCardTitle>{ (title + "").split(' ').slice(0,-1).join(' ') }</IonCardTitle>

                                  // <h5>{title}</h5>
                                }
                              </IonCardHeader>
                              <IonCardContent>
                                    <IonRow>
                                      <IonCol size='12'>
                                        <IonRow className="ion-align-items-start">
                                          <IonCol size='6'>Capacity </IonCol>
                                          <IonCol>{extInfo && JSON.parse(extInfo).capacity ? JSON.parse(extInfo).capacity + " Litres": "N.A."}</IonCol>
                                        </IonRow>
                                        <IonRow className="ion-align-items-start">
                                          <IonCol size='6'>Dimension (W x H x D) </IonCol>
                                          <IonCol>{widthCm*10} x {heightCm*10} x {depthCm*10} mm</IonCol>
                                        </IonRow>
                                      </IonCol>
                                      {/*
                                      <IonCol size='6'>
                                        {extInfo ? JSON.parse(extInfo).features : "--"}
                                      </IonCol>
                                      */}
                                    </IonRow>
                              </IonCardContent>
                          </IonCol>
                          </IonRow>
                          </IonGrid>
                      </IonCard>
                      ))
                    }
                </IonRow>
                {

                  // <ReactPaginate
                  //   previousLabel={'previous'}
                  //   nextLabel={'next'}
                  //   breakLabel={'...'}
                  //   breakClassName={'break-me'}
                  //   pageCount={pageCount}
                  //   marginPagesDisplayed={2}
                  //   pageRangeDisplayed={5}
                  //   onPageChange={handlePageClick}
                  //   containerClassName={'pagination'}
                  //   // subContainerClassName={'pages pagination'}
                  //   activeClassName={'active'}
                  // />
                }
              </IonCol>
          </IonRow>
          </IonGrid>
      </IonContent>
  );
};

export default Products;
