import React, {
  useState,
  useEffect
} from "react";

import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonToolbar,
  IonHeader,
  IonImg,
  IonThumbnail,
  IonContent,
  IonTitle,
  // isPlatform,
  getPlatforms
} from '@ionic/react';

import {
  Redirect,
  Route,
  useHistory
} from 'react-router-dom';

import {
  IonReactRouter
} from '@ionic/react-router';

import {
  cubeOutline,
  infiniteOutline,
  mapOutline,
  personOutline,
  // menuOutline,
  menu,
} from 'ionicons/icons';

import Tab1 from './pages/Tab1';
import Tab2 from './pages/Tab2';
import Tab3 from './pages/Tab3';
import Products from './pages/Products';
import ProductDetail from './pages/ProductDetail';
import AppUserGuide from './pages/AppUserGuide';

import 'bootstrap/dist/css/bootstrap.min.css';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

// import ReactPaginate from 'react-paginate';
// import Pagination from "react-paginating";
import Nav from "react-bootstrap/Nav";
// import Navbar from "react-bootstrap/Navbar";
// import NavDropdown from "react-bootstrap/NavDropdown";
import { LinkContainer } from "react-router-bootstrap";
// import DropdownButton from 'react-bootstrap/DropdownButton'
// import Dropdown from 'react-bootstrap/Dropdown'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import Button from 'react-bootstrap/Button'

/* Theme variables */
import './theme/variables.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Login from "./pages/Login";
import Signup from "./pages/Signup";
import { onError } from "./libs/errorLib";
import { AppContext } from "./libs/contextLib";
import { Auth } from "aws-amplify";
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';

import { AppVersion } from '@ionic-native/app-version/ngx';

const App: React.FC = () => {

  const history = useHistory();
  const [, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);

  useEffect(() => {

    const onLoad = async () => {
      try {
        await Auth.currentSession();
        userHasAuthenticated(true);
      }
      catch(e) {
        if (e !== 'No current user') {
          onError(e);
        }
      }

      setIsAuthenticating(false);
    }

    onLoad();
  }, []);

  const handleLogout= async ()=> {
    await Auth.signOut();
    userHasAuthenticated(false);
    // useHistory().push("/login");
  }
  console.log(getPlatforms())
  const is_mobile = getPlatforms().includes('mobile')

  return <IonReactRouter><IonApp>
  <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>

      {
        getPlatforms().includes('desktop') || true ?
        <IonHeader>
           <IonToolbar>
                <IonThumbnail slot="start" className="logoPadding" onClick={()=>{
                  window.location.href="/"
                }}>
                  <IonImg src="assets/icon/icon.png" />
                </IonThumbnail>

              <IonTitle>Home Appliances - HouseInnov</IonTitle>

              { (!getPlatforms().includes('hybrid')) &&

                (is_mobile ?
                <Nav activeKey={window.location.pathname} slot='end'>
                  <OverlayTrigger trigger="click" key='bottom' placement='bottom'
                    overlay={
                      <Popover id={`popover-positioned-bottom`}>
                        <Popover.Title as="h3" onClick={()=>{window.location.href="/signup"}}>Signup</Popover.Title>
                        <Popover.Content as="h6" onClick={()=>{window.location.href="/login"}}>Login</Popover.Content>
                      </Popover>
                    }
                  ><Button variant="link"><IonIcon icon={menu}/></Button>
                  </OverlayTrigger>
                  </Nav>
                :<Nav activeKey={window.location.pathname} slot='end'>
                  {isAuthenticated ? (
                    <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                  ) : (
                    <>
                      <LinkContainer to="/signup">
                        <Nav.Link>Signup</Nav.Link>
                      </LinkContainer>
                      <LinkContainer to="/login">
                        <Nav.Link>Login</Nav.Link>
                      </LinkContainer>
                    </>
                  )}
                </Nav>)

              }

{
  // <IonRow>
  //   <IonCol sizeLg='0.5' sizeSm='1' sizeMd='1' size='1'>
  //     <IonImg src="assets/icon/icon.png" class="logo icon-text-left"/>
  //   </IonCol>
  //   <IonCol size='5'>
  //      <h4>House99</h4>
  //   </IonCol>
  //   <IonCol >
  //    <Nav activeKey={window.location.pathname} slot='start'>
  //      {isAuthenticated ? (
  //        <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
  //      ) : (
  //        <>
  //          <LinkContainer to="/signup">
  //            <Nav.Link>Signup</Nav.Link>
  //          </LinkContainer>
  //          <LinkContainer to="/login">
  //            <Nav.Link>Login</Nav.Link>
  //          </LinkContainer>
  //        </>
  //      )}
  //    </Nav>
  //  </IonCol>
             // </IonRow>
}
        </IonToolbar>
         </IonHeader>
          :
          <IonTabs>
            <IonRouterOutlet>
              <Route path="/login" component={Login} exact={true} />
              <Route path="/tab1" component={Tab1} exact={true} />
              <Route path="/tab2" component={Tab2} exact={true} />
              <Route path="/tab3" component={Tab3} />
              <Route path="/" render={() => <Redirect to="/tab1" />} exact={true} />
            </IonRouterOutlet>

            <IonTabBar slot={getPlatforms()[0]==='desktop'?"top":"bottom"}>

              <IonTabButton tab="tab1" href="/tab1">
                <IonIcon icon={cubeOutline} />{}
                <IonLabel>Tab 1
                  {getPlatforms()}
                </IonLabel>
              </IonTabButton>
              <IonTabButton tab="tab2" href="/tab2">
                <IonIcon icon={infiniteOutline} />
                <IonLabel>Tab 2</IonLabel>
              </IonTabButton>
              <IonTabButton tab="tab3" href="/tab3">
                <IonIcon icon={mapOutline} />
                <IonLabel>Tab 3</IonLabel>
              </IonTabButton>
              <IonTabButton tab="tab3" href="/tab3">
                <IonIcon icon={personOutline} />
                <IonLabel>Tab 4</IonLabel>
              </IonTabButton>
            </IonTabBar>
          </IonTabs>
      }
      <IonContent>
         <IonRouterOutlet>
           <Route path="/login" component={Login} exact={true} />
           <Route path="/signup" component={Signup} exact={true} />
           <Route path="/home-appliances/*" component={Products} exact={true} />
           <Route path="/home-appliance/*" component={ProductDetail} exact={true} />
           <Route path="/app-user-guide" component={AppUserGuide} exact={true} />
           <Route path="/tab1" component={Tab1} exact={true} />
           <Route path="/tab2" component={Tab2} exact={true} />
           <Route path="/tab3" component={Tab3} />
           <Route path="/" render={() => <Redirect to="/home-appliances/" />} exact={true} />
         </IonRouterOutlet>
       </IonContent>
    </AppContext.Provider>
  </IonApp>
</IonReactRouter>
};

export default App;
