import React, {
  useState,
  useEffect
} from 'react';

// import { useHistory } from "react-router-dom";
import { IonRow, IonCol, IonContent,
  IonHeader, IonPage, IonTitle, IonToolbar, IonCard,
   IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent,
  IonItem, IonIcon, IonLabel, IonButton,
  IonGrid,
  IonButtons} from '@ionic/react';
import { bagHandleOutline, documentTextOutline, arrowBackOutline} from 'ionicons/icons';
import { onError } from "../libs/errorLib";
import { catInfoByTypeId } from "./ProdMeta"
import { API } from "aws-amplify";


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import '../theme/variables.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-bootstrap/Carousel'

const ProductDetail: React.FC = () => {

  const [isAuthenticated] = useState(false);
  const [prodInfo, setProdInfo] = useState({
      imagesUri: "[]",
      brand: "Brand ..",
      depthCm: 0.0,
      extInfo: "{}",
      heightCm: 0.0,
      id: 1,
      model: "Model ..",
      price: 0.0,
      productType: 4,
      title: "..",
      weightKg: 0.0,
      widthCm: 0.0,
  });
  // const history = useHistory();

  useEffect(() => {
    let isMounted = true
    async function onLoad() {
      // if (!isAuthenticated) {
      //   console.log("No isAuthenticated")
      //   return;
      // }
      // await getUserFilters()
      // setCat('fridge');
      // updateProdList(-1);
      // setProdInfo({})
      updateProdDetail()
    }
    if( isMounted ){
      onLoad()
    }
    return function cleanup(){
      isMounted = false
    }

  }, [isAuthenticated]);

  const updateProdDetail= async()=>{

    var url = new URL(window.location.href);
    const pathSeg = url.pathname.split('/');
    const prodId = (pathSeg.length > 2 && /^\d+$/.test(pathSeg[2]))? parseInt(pathSeg[2]): 1
    // setIsLoading(true);
    try {
      // setProducts([]);
      const prods = await API.get("notes", "/get-product"  , {
        headers: {}, // OPTIONAL
        response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
        queryStringParameters: {
          "prodid": prodId
        }
      });

      setProdInfo(prods.data.found)
      console.log(prodInfo)
      // console.log(prods.data.found)
      // setTotalItems(prods.data.total);
    } catch (e) {
      onError(e);
    }

    // setActivePage(currentPage) ;
    // setIsLoading(false);
  }

  console.dir(catInfoByTypeId)
  console.log(prodInfo.productType )
  const prodSubCat = catInfoByTypeId[ prodInfo.productType ].subCatName
  document.title = prodSubCat + " - " + prodInfo.title.slice(0,20)  + (prodInfo.title.length>20? "...": "") + " - HouseInnov"
  function openInNewTab(ahref:string) {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      href: ahref,
    }).click();
  }

  return  <IonPage>
      <IonHeader>
        <IonToolbar>
        <IonButtons slot="start" >
          <IonButton href="/home-appliances/">
            <IonIcon icon={arrowBackOutline} slot="start" color="primary"/><IonLabel color="primary">Back</IonLabel>
          </IonButton>
        </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{prodInfo.brand}  {catInfoByTypeId[ prodInfo.productType ].subCatName}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
      <IonGrid>
        <IonRow>
          <IonCol size="12" sizeSm="12" sizeMd="4" sizeLg="5">
            <IonCard>
              <Carousel>
                {   prodInfo.imagesUri &&
                    JSON.parse(prodInfo.imagesUri).map((imgUri:string)=>{
                      return <Carousel.Item key={imgUri}>
                        <br/>
                        <img
                          className="d-block w-100"
                          src={"https://house99-res.s3-ap-southeast-1.amazonaws.com/product_imgs/" + imgUri}
                          alt="Product Pic"
                        />
                      </Carousel.Item>
                    })
                }

              </Carousel>
              <IonCardHeader>
                <IonCardSubtitle>{ (prodInfo.title + "").split(' ').slice(0,-1).join(' ') }</IonCardSubtitle>
                <IonCardTitle>{prodInfo.brand}-{prodInfo.model}</IonCardTitle>

              </IonCardHeader>
            </IonCard>
          </IonCol>
          <IonCol>
          {

            <IonCard>
              <IonItem>
                <IonIcon icon={documentTextOutline} slot="start" />
                <IonLabel>Specifications</IonLabel>
                {
                // <IonButton fill="outline" slot="end">View</IonButton>
                }
              </IonItem>
              <IonCardContent>
                <IonRow>
                  <IonCol size='12'>
                  {prodInfo.extInfo && <IonRow className="ion-align-items-start">
                      <IonCol size='6'>Net Capacity</IonCol>
                      <IonCol>{prodInfo.extInfo ? JSON.parse(prodInfo.extInfo).capacity + "L" : "N.A."}</IonCol>
                    </IonRow>
                  }
                    <IonRow className="ion-align-items-start">
                      <IonCol size='6'>Width </IonCol>
                      <IonCol>{prodInfo.widthCm*10} mm</IonCol>
                    </IonRow>
                    <IonRow className="ion-align-items-start">
                      <IonCol size='6'>Height </IonCol>
                      <IonCol>{prodInfo.heightCm*10} mm</IonCol>
                    </IonRow>
                    <IonRow className="ion-align-items-start">
                      <IonCol size='6'>Depth </IonCol>
                      <IonCol>{prodInfo.depthCm*10} mm</IonCol>
                    </IonRow>

                  </IonCol>
                </IonRow>
              </IonCardContent>

            </IonCard>
          }


          <IonCard>
            <IonItem className="ion-activated">
              <IonIcon icon={bagHandleOutline} slot="start" />
              <IonLabel>Singapore Retailers : </IonLabel>
            </IonItem>

            <IonItem href='#'  onClick={(e)=>{
              openInNewTab("https://www.harveynorman.com.sg/index.php?subcats=Y&status=A&pshort=N&pfull=N&pname=Y&pkeywords=Y&search_performed=Y&q="+
              prodInfo.model + "&dispatch=products.search");
              e.preventDefault();
            }}>
              <IonLabel>HarveyNorman</IonLabel>
            </IonItem>

            <IonItem className="ion-activated"
              href='#'  onClick={(e)=>{
                openInNewTab("https://www.courts.com.sg/catalogsearch/result/?q=" + prodInfo.model);
                e.preventDefault();
              }}>
              <IonLabel>Courts</IonLabel>
            </IonItem>

            <IonItem href='#'  onClick={(e)=>{
                openInNewTab('https://www.bestdenki.com.sg/search?search=' + prodInfo.model);
                e.preventDefault();
              }}>
              <IonLabel>Best Denki</IonLabel>
            </IonItem>
          </IonCard>
        </IonCol>
        </IonRow>
        </IonGrid>
        <br/>
        <br/>
        <br/>

      </IonContent>
    </IonPage>


};

export default ProductDetail;
