
const catByName: any = {
  'Refrigerators': {
    '4': '2-Door Fridges',
    '16':	'Side-by-Side Fridges',
    '17':	'Multi-Door Fridges',
    '21':	'3-Door Fridges',
    '24':	'Bar Fridges',
    '27':	'1-Door Fridges',
    '28':	'Upright Freezers',
    '45':	'Chest Freezers',
    '50':	'Built-In Refrigerators'
  },
  'Ovens': {
    '5':	'Microwave Ovens',
    '12':	'Electric Ovens',
    '25':	'Built-in Steam Ovens',
    '26':	'Air Ovens',
    '32':	'Steam Ovens',
    '33':	'Built-in Ovens',
    '39':	'Ovens',
    '40':	'Built-in Microwave Ovens',
    '51': 'Toaster Oven',
  },
  'Hobs': {
    '29':	'Built-in Induction Hobs',
    '31':	'Built-in Gas Hobs',
    '43':	'Ceramic Hobs',
    '52':	'Electric Hobs'
  }
}

const catInfoByTypeId :any = {}

for( let cn in catByName){
  for( let typeid in catByName[cn] ){
    catInfoByTypeId[typeid] = {
      'catName': cn,
      'subCatName': catByName[cn][typeid].endsWith('s') ? catByName[cn][typeid].slice(0,-1): catByName[cn][typeid]
    }
  }
}

export {
  catByName,
  catInfoByTypeId
}
